import { render, staticRenderFns } from "./tablo-block.vue?vue&type=template&id=09eab64a"
import script from "./tablo-block.vue?vue&type=script&lang=js"
export * from "./tablo-block.vue?vue&type=script&lang=js"
import style0 from "./tablo-block.vue?vue&type=style&index=0&id=09eab64a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzButton: require('/opt/iaravia.ru/node_modules/@razlet/ui/src/components/button/index.js').default,RzAirlineIcon: require('/opt/iaravia.ru/node_modules/@razlet/ui/src/components/airline-icon/index.vue').default,RzSimpleTable: require('/opt/iaravia.ru/node_modules/@razlet/ui/src/components/simple-table/index.vue').default,RzPreloader: require('/opt/iaravia.ru/node_modules/@razlet/ui/src/components/preloader/index.vue').default,RzIconReturn: require('/opt/iaravia.ru/node_modules/@razlet/ui/src/icons/icon-return/index.vue').default,RzTabs: require('/opt/iaravia.ru/node_modules/@razlet/ui/src/components/tabs/index.vue').default})
