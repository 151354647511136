
export default {
  name: 'schedule',

  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableHead: [
        { name: 'Авиакомпания' },
        { name: 'Рейс' },
        { name: 'Направление' },
        { name: 'Дни вылета' },
        { name: 'Вылет' },
        { name: 'Прилет' },
        { name: 'Навигация' },
      ],
      tableHeadMobile: [
        { name: 'Данные рейса' },
        { name: 'Время' },
      ],
      activeTab: 'departure',
      tabs: [
        { value: 'departure', title: 'Вылет', icon: 'rz-icon-flight-start' },
        { value: 'arrival', title: 'Прилет', icon: 'rz-icon-flight-end' },
      ],
      date: {},
      airline: {},
      selectKey: 0,
      currentWeekday: null,
    };
  },

  computed: {
    airlineOptions () {
      if (!this.list || !this.list.length) return [];
      const companies = Array.from(new Set(this.list?.map(item => item.companyName) || []));
      return companies.map(company => ({ label: company, value: company }));
    },
    airlines () {
      if (!this.airline) return [];
      const keys = Object.keys(this.airline);
      return keys.filter(key => this.airline[key]);
    },
    filteredSchedule () {
      if (!this.list || !this.list.length || !this.list.filter) return [];
      if (this.isEmptyFilter) return this.list;

      const filterDate = this.date && this.date.date && this.date.month
        ? new Date(`${this.date.year}-${this.date.month.value}-${this.date.date}`)
        : null;

      if (filterDate) filterDate.setHours(0, 0, 0);

      return this.list.filter(flight => {
        const activeDays = flight.days.flatMap(day => day.active ? [day.id] : []);
        const airlineValid = this.airlines.includes(flight.companyName) || !this.airlines.length;
        const dayValid = activeDays.includes(this.currentWeekday) || !this.isDateSelected;
        const dateValid = (
          filterDate &&
          filterDate.getTime() >= flight.beginDate.getTime() &&
          filterDate.getTime() <= flight.endDate.getTime()
        ) || !this.isDateSelected;

        return airlineValid && dayValid && dateValid;
      });
    },
    isEmptyFilter () {
      return (
        !this.airline ||
        (Object.values(this.airline).every(item => !item))
      ) && !this.isDateSelected;
    },
    isDateSelected () {
      return Object.keys(this.date).length;
    },
    nextYear () {
      return new Date().getFullYear() + 1;
    },
  },

  watch: {
    date: {
      handler (value) {
        if (Object.keys(value).length) this.currentWeekday = this.getWeekday(value);
      },
      deep: true,
    },
  },

  methods: {
    getWeekday(date) {
      const value = new Date(`${date.month.value}/${date.date}/${date.year}`);
      return value.getDay() || 7;
    },
    resetFilter() {
      this.$set(this, 'airline', {});
      this.selectKey += 1;
      this.date = {};
    },
    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('refresh', tab);
    },
  },
};
