import Vue from 'vue';
import { date02format } from '@razlet/sdk/lib/utils/date';
import { isTimeInRange } from '~/utils/index';

// function fillDate(date) {
//   return {
//     date: date02format(date.getDate()),
//     month: {
//       label: DateConf[Vue.prototype.$locale].months[date.getMonth()].full,
//       value: date02format(date.getMonth()+1),
//     },
//     year: date.getFullYear(),
//   };
// }

const TIME_RANGE = 6;
const LAST_TIME_PERIOD = 24 - TIME_RANGE;

export default class {
  constructor(isFullTime = false) {
    const date = new Date();
    const dateStringInTZ = date.toLocaleString('en-EN', { 
      timeZone: 'Asia/Bishkek',
    });

    this.dateInTz = new Date(dateStringInTZ);

    // this.today = fillDate(this.dateInTz);
    this.today = 'today';

    const currentTime = `${this.dateInTz.getHours()}:${this.dateInTz.getMinutes()}`;
    this.timeOptions = [];

    for (let i = 0; i < 24; i += TIME_RANGE) {
      const firstHour = i;
      const secondHour = i === LAST_TIME_PERIOD ? 0 : i + TIME_RANGE;
      const index = i / TIME_RANGE;
      const interval = `${date02format(firstHour)}:00-${date02format(secondHour)}:00`;

      const option = {
        label: interval,
        value: index,
        name: index,
      };
      this.timeOptions.push(option);

      if (i === LAST_TIME_PERIOD) {
        this.timeOptions.push({
          label: Vue.prototype.$localize.t('tablo.filter.allDay'),
          value: index + 1,
          name: index + 1,
        });
      }
      if (!isFullTime && isTimeInRange(currentTime, interval)) {
        this.time = option;
      } 
    }
    if (!this.time) this.time = this.timeOptions[this.timeOptions.length - 1];
    this.day = this.today;
    this.directions = [
      {
        id: 1,
        label: Vue.prototype.$localize.t('tablo.filter.directions[0]'),
        value: 'departure',
        altValue: 'from',
      },
      {
        id: 2,
        label: Vue.prototype.$localize.t('tablo.filter.directions[1]'),
        value: 'arrival',
        altValue: 'to',
      },
    ];
    [this.direction] = this.directions;
    this.term = '';
  }

  // get formattedDate() {
  //   if (!this.date) return null;
  //   return `${this.date.year}-${this.date.month.value}-${this.date.date}`;
  // }

  setDateByDay (day = 'today') {
    this.day = day;
    // if (day === 'today') this.date = this.today;
    // if (day === 'tomorrow') {
    //   const tomorrow = new Date(this.dateInTz);
    //   tomorrow.setDate(tomorrow.getDate() + 1);
    //   this.date = fillDate(tomorrow);
    // } 
    // if (day === 'yesterday') {
    //   const tomorrow = new Date(this.dateInTz);
    //   tomorrow.setDate(tomorrow.getDate() - 1);
    //   this.date = fillDate(tomorrow);
    // } 
  }

  // nextDay() {
  //   this.dateInTz.setDate(this.dateInTz.getDate() + 1);
  //   this.date = fillDate(this.dateInTz);
  // }

  fillFilter(query) {
    if (query.day) {
      // const date = new Date(query.date.replace(/-/g, '/'));
      this.day = query.day;
    }
    if (query.direction) {
      this.direction = this.directions.find(direction => direction.value === query.direction);
    }
    if (query.term) {
      this.term = query.term;
    }
  }

  buildRequest(city) {
    const params = {
      city,
      day: this.day,
      direction: this.direction.value,
      term: this.term,
    };

    return params;
  }
}